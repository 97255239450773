import React, { useEffect, useState } from "react";
import axios from "axios";
import "./style.scss";
import Card from "../../components/Card/Card";
import { useParams, useSearchParams } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";

export default function Products() {
  const [products, setProducts] = useState([]);
  const { categoryId, subcategoryId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  // Отримуємо номер сторінки з параметрів запиту або встановлюємо 1 за замовчуванням
  const currentPageParam = parseInt(searchParams.get("page")) || 1;
  const [currentPage, setCurrentPage] = useState(currentPageParam);
  const [totalPages, setTotalPages] = useState();
  const url = process.env.REACT_APP_BACKEND_URL;

  // Оновлюємо currentPage, коли параметри запиту змінюються
  useEffect(() => {
    setCurrentPage(currentPageParam);
  }, [currentPageParam]);

  useEffect(() => {
    const fetchProducts = async (page) => {
      let fetchUrl = `${url}/api/category/${categoryId}/products?page=${page}`;

      if (subcategoryId) {
        fetchUrl = `${url}/api/subcategory/${subcategoryId}/products?page=${page}`;
      }

      try {
        const response = await axios.get(fetchUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setProducts(response.data.results);
        const totalPages = Math.ceil(response.data.count / 12);
        setTotalPages(totalPages);
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]);
      }
    };

    if (subcategoryId || categoryId) {
      fetchProducts(currentPage);
    }
  }, [currentPage, subcategoryId, categoryId, url]);

  // Встановлюємо сторінку 1 при зміні категорії або підкатегорії
  // useEffect(() => {
  //   if (subcategoryId || categoryId) {
  //     setSearchParams({ page: 1 });
  //   }
  // }, [setSearchParams]);

  // Функція для зміни сторінки
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSearchParams({ page });
  };

  console.log("Категорія: ", categoryId);
  console.log("Підкатегорія: ", subcategoryId);

  return categoryId !== undefined ? (
    <div className="container">
      <div className="cards">
        <div className="row g-4">
          {products &&
            products.map((product) => (
              <Card key={product.id} product={product} />
            ))}
        </div>
        <Pagination
          setCurrentPage={handlePageChange}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  ) : (
    <div className="container">
      <div className="placetext">
        <h4>Будь ласка, виберіть категорію товарів для продовження!</h4>
      </div>
    </div>
  );
}
