import React from "react";
import "./style.scss";

const Pagination = ({ setCurrentPage, currentPage, totalPages }) => {
  const pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }
  if (pages.length > 1)
    return (
      <nav className="pagination-holder">
        <ul className="pagination">
          {pages.map((page) => (
            <li
              onClick={() => setCurrentPage(page)}
              key={page}
              className={`item ${currentPage === page ? "active" : ""}`}
            >
              {page}
            </li>
          ))}
        </ul>
      </nav>
    );
};

export default Pagination;
