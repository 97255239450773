// src/components/Countdown.js

import React, { useEffect, useState } from "react";
import "./style.scss";

export default function Countdown({ sale_end }) {
  const calculateTimeRemaining = (endDate) => {
    const now = new Date();
    const difference = endDate - now;

    let time = {};

    if (difference > 0) {
      time = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      time = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return time;
  };

  const [timeRemaining, setTimeRemaining] = useState(() => {
    const endDate = new Date(sale_end);
    return calculateTimeRemaining(endDate);
  });

  useEffect(() => {
    const endDate = new Date(sale_end);

    const timer = setInterval(() => {
      const remaining = calculateTimeRemaining(endDate);
      setTimeRemaining(remaining);

      // Якщо час закінчився, очищуємо інтервал
      if (
        remaining.days === 0 &&
        remaining.hours === 0 &&
        remaining.minutes === 0 &&
        remaining.seconds === 0
      ) {
        clearInterval(timer);
      }
    }, 1000);

    // Очищаємо інтервал при розмонтуванні компонента
    return () => clearInterval(timer);
  }, [sale_end]);

  return (
    <>
      <spam>Акція закінчиться через:</spam>
      <div className="countdown">
        {timeRemaining.days > 0 && (
          <span className="countdown-item">{timeRemaining.days}д </span>
        )}
        {(timeRemaining.hours > 0 || timeRemaining.days > 0) && (
          <span className="countdown-item">{timeRemaining.hours}г </span>
        )}
        {(timeRemaining.minutes > 0 ||
          timeRemaining.hours > 0 ||
          timeRemaining.days > 0) && (
          <span className="countdown-item">{timeRemaining.minutes}хв </span>
        )}
        <span className="countdown-item">{timeRemaining.seconds}с </span>
        {timeRemaining.days === 0 &&
          timeRemaining.hours === 0 &&
          timeRemaining.minutes === 0 &&
          timeRemaining.seconds === 0 && (
            <span className="countdown-ended">Акція завершена!</span>
          )}
      </div>
    </>
  );
}
