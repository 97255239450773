import { useState } from "react";
import SimpleButton from "../../components/buttons/SimpleButton/SimpleButton";
import placeholderImage from "../../assets/graphics/placeholder.webp";
import { Link } from "react-router-dom";
import "./style.scss";

export default function Card({ product, index }) {
  const [imageSrc, setImageSrc] = useState(
    product.images && product.images.length > 0
      ? product.images[0].image
      : placeholderImage
  );

  const isComposite = Array.isArray(product.products);
  const productPath = isComposite
    ? `/composite-product/${product.id}`
    : `/product/${product.id}`;

  return (
    <div className="col-12 col-md-6 col-lg-4">
      <Link className="product-card" to={productPath}>
        <div key={index} className="holder">
          <img
            className="image"
            src={imageSrc}
            alt={`Product ${product.title}`}
            onError={() => setImageSrc(placeholderImage)}
          />
          <div className="details">
            <div className="text">
              <span className="title">{product.title}</span>
              {product.price && <span>{product.price} грн</span>}
            </div>
            <SimpleButton title={"Купити"} className={"buy-button"} />
          </div>
        </div>
      </Link>
    </div>
  );
}
