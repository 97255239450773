import { useState, useEffect } from "react";
import arrowRight from "../../assets/icons/chevron-right-solid.svg";
import { useNavigate } from "react-router-dom";
import CarouselItem from "./CarouselItem/CarouselItem";
import "./style.scss";

export default function Carousel({ items }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BACKEND_URL;

  const handleElementClick = (id) => {
    navigate(`/product/${id}`);
  };

  const goToNext = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex + 2;
      return newIndex < items.length ? newIndex : 0;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 9000);

    return () => clearInterval(interval);
  }, [items.length]);

  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        {items.map((item, index) => (
          <div
            key={item.id} // Використовуйте унікальний ідентифікатор замість index
            className={`carousel-item ${index === activeIndex ? "active" : ""}`}
          >
            <div className="carousel-card">
              <CarouselItem
                item={item}
                url={url}
                onClick={handleElementClick}
              />
              {items[index + 1] && (
                <CarouselItem
                  item={items[index + 1]}
                  url={url}
                  onClick={handleElementClick}
                />
              )}
              <button className="next" onClick={goToNext}>
                <img className="arrow" src={arrowRight} alt="arrow" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
