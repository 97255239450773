// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countdown {
  border: 1px solid #490515;
  border-radius: 4px;
}

.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  font-size: 1.5rem;
  font-weight: 300;
  color: black;
  line-height: 24px;
  letter-spacing: 0em;
  padding: 0 1rem;
}
.countdown span {
  padding-left: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/_variables.scss","webpack://./src/pages/Product/Countdown/style.scss"],"names":[],"mappings":"AAGA;EACE,yBAAA;EACA,kBAAA;ACFF;;AADA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;AAGF;AAFE;EACE,oBAAA;AAIJ","sourcesContent":["$background-color: white;\n$main-purple: #490515;\n\n%border-style {\n  border: 1px solid $main-purple;\n  border-radius: 4px;\n}\n\n%card-style {\n  border: none;\n  border-radius: 4px;\n  box-shadow: 1px 4px 4px 0px rgba(73, 5, 21, 0.25);\n  background: linear-gradient(180deg, #d2d2d219 68.82%, #e0e0e0af 100%);\n\n  img {\n    max-width: 100%;\n  }\n  .details {\n    margin: 0.5rem 0 0 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    .text {\n      display: flex;\n      flex-direction: column;\n    }\n    span {\n      color: #000;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n    }\n  }\n}\n\n%overflow-style {\n  &::-webkit-scrollbar {\n    width: 3px;\n    height: 3px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: transparent; // Ensures the track is transparent\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: transparent; // Initially transparent\n    border-radius: 3px; // Optional: To make the scrollbar thumb rounded\n  }\n\n  &:hover::-webkit-scrollbar-thumb {\n    background-color: $main-purple; // Changes to main purple when hovered\n  }\n\n  &:active::-webkit-scrollbar-thumb {\n    background-color: $main-purple; // Changes to orange when active (scrolling)\n  }\n}\n","@import \"../../../styles/variables\";\n\n.countdown {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  @extend %border-style;\n  height: 2.5rem;\n  font-size: 1.5rem;\n  font-weight: 300;\n  color: black;\n  line-height: 24px;\n  letter-spacing: 0em;\n  padding: 0 1rem;\n  span {\n    padding-left: 0.3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
