// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales {
  border: none;
  border-radius: 4px;
  box-shadow: 1px 4px 4px 0px rgba(73, 5, 21, 0.25);
  background: linear-gradient(180deg, rgba(210, 210, 210, 0.0980392157) 68.82%, rgba(224, 224, 224, 0.6862745098) 100%);
}
.sales img {
  max-width: 100%;
}
.sales .details {
  margin: 0.5rem 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sales .details .text {
  display: flex;
  flex-direction: column;
}
.sales .details span {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.sales {
  padding: 1rem 0;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/_variables.scss","webpack://./src/components/Sales/style.scss"],"names":[],"mappings":"AAQA;EACE,YAAA;EACA,kBAAA;EACA,iDAAA;EACA,qHAAA;ACPF;ADSE;EACE,eAAA;ACPJ;ADSE;EACE,oBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;ACPJ;ADQI;EACE,aAAA;EACA,sBAAA;ACNN;ADQI;EACE,WAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;ACNN;;AAtBA;EAEE,eAAA;EACA,YAAA;AAwBF","sourcesContent":["$background-color: white;\n$main-purple: #490515;\n\n%border-style {\n  border: 1px solid $main-purple;\n  border-radius: 4px;\n}\n\n%card-style {\n  border: none;\n  border-radius: 4px;\n  box-shadow: 1px 4px 4px 0px rgba(73, 5, 21, 0.25);\n  background: linear-gradient(180deg, #d2d2d219 68.82%, #e0e0e0af 100%);\n\n  img {\n    max-width: 100%;\n  }\n  .details {\n    margin: 0.5rem 0 0 0;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    .text {\n      display: flex;\n      flex-direction: column;\n    }\n    span {\n      color: #000;\n      font-size: 14px;\n      font-style: normal;\n      font-weight: 400;\n    }\n  }\n}\n\n%overflow-style {\n  &::-webkit-scrollbar {\n    width: 3px;\n    height: 3px;\n  }\n\n  &::-webkit-scrollbar-track {\n    background: transparent; // Ensures the track is transparent\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: transparent; // Initially transparent\n    border-radius: 3px; // Optional: To make the scrollbar thumb rounded\n  }\n\n  &:hover::-webkit-scrollbar-thumb {\n    background-color: $main-purple; // Changes to main purple when hovered\n  }\n\n  &:active::-webkit-scrollbar-thumb {\n    background-color: $main-purple; // Changes to orange when active (scrolling)\n  }\n}\n","@import \"../../styles/variables\";\n\n.sales {\n  @extend %card-style;\n  padding: 1rem 0;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
