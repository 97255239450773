import { useState } from "react";
import placeholderImage from "../../../assets/graphics/placeholder.webp";
import "./style.scss";

export default function CarouselItem({ item, onClick }) {
  const [imageSrc, setImageSrc] = useState(
    item.images && item.images.length > 0
      ? item.images[0].image
      : placeholderImage
  );

  const handleImageError = () => {
    setImageSrc(placeholderImage);
  };

  return (
    <div className="element" onClick={() => onClick(item.id)}>
      <img src={imageSrc} alt={item.name} onError={handleImageError} />
      <span>{item.title}</span>
      {item.is_on_sale && (
        <span className="discount">Знижка {item.discount}%</span>
      )}
    </div>
  );
}
