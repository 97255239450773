// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
  padding: 0 0 3rem 0;
  min-height: 90vh;
}
.home .row {
  margin: 2rem 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/style.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;EACA,gBAAA;AADF;AAEE;EACE,cAAA;AAAJ","sourcesContent":["@import \"../../styles/variables\";\n\n.home {\n  padding: 0 0 3rem 0;\n  min-height: 90vh;\n  .row {\n    margin: 2rem 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
