import Badges from "../../components/Badges/Badges";
import Proposals from "../../components/Proposals/Proposals";
import Sales from "../../components/Sales/Sales";
import Search from "../../components/Search/Search";
import Welcome from "../../components/Welcome/Welcome";
import "./style.scss";

export default function Home() {
  return (
    <section className="home">
      <div className="container">
        <Search />
        <Welcome />
        <div className="row">
          <div className="col col-12 col-md-6 d-none d-md-block">
            <Proposals />
          </div>
          <div className="col col-12 col-md-6">
            <Sales />
          </div>
        </div>
        <Badges />
        <Proposals className={"col-12 col-md-6 d-md-none"} />
      </div>
    </section>
  );
}
